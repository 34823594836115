<template>
  <v-dialog width="625" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn outlined block v-on="on" @click="fetchArchives" :height="'30px'"
        >Configurar</v-btn
      >
    </template>
    <v-card>
      <v-card-title>{{ $t("archivar") }}</v-card-title>

      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-spacer></v-spacer>
          <New @save="fetchArchives" ref="new" />
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :server-items-length="total"
        :options.sync="options"
      >
        <template v-slot:item.date="{ item }">
          {{ $d(new Date(item.date), "date2digits") }}
        </template>
        <template v-slot:item.camps="{ item }"> {{ camps(item) }} </template>
        <template v-slot:item.actions="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" large>
                mdi-dots-horizontal
              </v-icon>
            </template>
            <v-list>
              <Update @update="fetchArchives" :archive="item" />
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ArchivateDialog",
  data() {
    return {
      headers: [
        { text: this.$t("events.date"), value: "date" },
        { text: this.$t("archives.camps"), value: "camps" },
        { text: this.$t("actions"), value: "actions" },
      ],
      options: {},
      total: null,
      items: [],
      dialog: false,
    };
  },
  components: {
    New: () => import("@/components/user/settings/configuracion/archive/New"),
    Update: () =>
      import("@/components/user/settings/configuracion/archive/Update"),
  },
  mounted() {
    this.fetchArchives();
  },
  methods: {
    ...mapActions("archive", ["getArchives"]),
    fetchArchives() {
      this.getArchives({
        filters: { studio: this.$store.state.auth.user.studio.id },
        pagination: this.options,
      }).then((response) => {
        this.total = response.total;
        if (this.dialog && this.total === 0) this.$refs.new.dialog = true;
        this.items = response.data;
      });
    },
    camps(archive) {
      let text = "";
      Object.keys(archive).forEach((key) => {
        switch (key) {
          case "tattooer":
          case "appointment":
          case "expense":
          case "customer":
          case "inventory":
            if (archive[key]) {
              if (text.length) text += ", ";
              text += this.$tc(
                key == "tattooer"
                  ? "artists"
                  : key == "expense"
                  ? "economy"
                  : key,
                2
              );
            }
            break;
        }
      });
      if (!text.length) text = "-";
      return text;
    },
  },
};
</script>